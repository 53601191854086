/* Popup style */
.popup {
  position: fixed;
  z-index: 1;
  height: 100%;
  width: 100%;
  left: 0;
  top: 0;
  background-color: rgba(56, 53, 53, 0.466);
}

.box {
  background-color: white;
  margin: 10% auto; /* 15% from the top and centered */
  padding: 2vw;
  border: 1px solid #888;
  border-radius: 15px;
  overflow: auto;
  width: 60%; /* Could be more or less, depending on screen size */
}

@media only screen and (max-width: 600px) {
  .box {
    margin: 25% auto;
  }
}
