.form {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
}

#Input {
  width: 28%;
  margin: 1vw 2.5vh;
  font-size: 2vh;
  border-radius: 8px;
  padding: 1vw;
  border: solid 2px #969696;
}

@media only screen and (max-width: 600px) {
  #Input {
    width: 70%;
    padding: 1vw;
    margin: 2vw;
  }
}
