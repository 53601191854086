.dash {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
}

.desc {
  margin: 13vh 10vh 0 10vh;
  display: flex;
  flex-direction: column;
  align-self: center;
}

.dash h1 {
  padding: 0px;
  margin: 0px;
  font-size: 4.25vw;
  font-weight: 800;
}

.dash h2 {
  font-size: 1.5vw;
  margin: 1vw;
}

.insights h2 {
  font-size: 2.5vw;
  padding-left: 0;
  margin: 1vw;
  margin-left: 0;
}

.dash p {
  color: #5f5f5f;
  font-weight: 500;
  font-size: 1.1vw;
}

.products {
  margin: 0 15vw;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  flex-grow: 1;
  justify-content: center;
  position: relative;
}

.products div {
  background-color: #ffff;
  width: 9vw;
  height: 8vw;
  box-shadow: 0.1vw 0.1vw 0.2vw 0.2vw rgba(95, 95, 95, 0.16);
  margin: 1vw;
  text-align: center;
  border-radius: 0.5vw;
  padding: 2vw;
}

.products div:hover {
  box-shadow: 0.3vw 0.3vw 0.4vw 0.4vw rgba(95, 95, 95, 0.16);
}

.products img {
  height: 5vw;
  width: 80%;
  align-self: auto;
}

.products p {
  margin-top: 0;
  font-size: 1.2vw;
  font-weight: 500;
  color: #000;
}

.quotes {
  width: 100%;
  background-color: #f9f8f4;
  margin-top: -8vw;
  padding-top: 10vw;
}

.insights {
  padding: 2vw 12vw;
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: left;
}

.insight-box {
  width: 28vw;
  height: 28vw;
  min-width: 55%;
}

.insights div {
  padding: 2vw;
  padding-top: 2vw;
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.insight-content {
  padding: 2vw;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.contact {
  display: flex;
  flex-direction: row;
  justify-content: center;
  text-align: left;
  margin: 8vw 0;
}

.contact form {
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding-left: 3vw;
  width: 70%;
}
.contact input {
  margin: 1vw;
  padding: 0.5vw;
  border-radius: 6px;
  border: solid 2px #8f8585;
  font-size: 1.25vw;
}

.contact div {
  width: 19vw;
}

@media screen and (max-width: 600px) {
  .contact {
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
  }

  .contact div {
    width: 80%;
    text-align: center;
  }
}

.info {
  display: flex;
  margin: 10vh 8vw;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  text-align: start;
}

.a {
  width: 20%;
  height: 15vh;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.info p,
h3 {
  padding: 0;
  margin: 0;
}

.info p {
  font-size: 2vh;
}

@media only screen and (max-width: 600px) {
  .info {
    flex-direction: column;
  }

  .a {
    width: 80%;
    height: none;
  }
}
