.nav {
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.16);
  background-color: #fff;
  display: flex;
  justify-content: space-between;
  height: 60px;
  width: -webkit-fill-available;
  padding: 0 12%;
  overflow: hidden;
  position: fixed;
  z-index: 10;
}

@media only screen and (max-width: 600px) {
  .nav {
    height: 40px;
  }
}

.logo {
  height: 30px;
  width: 25%;
  cursor: pointer;
  align-self: center;
}

.nav-bar {
  list-style: none;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 35%;
  padding-left: 0;
}

.nav-bar li {
  font-size: 1vw;
  font-weight: 500;
  color: #5f5f5f;
}
.nav-link {
  text-decoration: none;
  font-weight: 500;
  width: 20%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-self: center;
  color: #5f5f5f;
}
